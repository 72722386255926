import AbstractEntity from "./AbstractEntity";
import JobEntity from "./JobEntity";

export default class MessageEntity extends AbstractEntity {
    static TABLE = 'messages';

    static schema = {
        id: "string",
        remoteId: "number",
        conversationId: "number",
        conversationRemoteId: "number",
        content: "string",
        prompt: "string",
        categories: "string",
        role: "string",
        createdAt: "string",
        isFinish: "bool"
    };

    constructor(object) {
        super();  // Call the parent constructor (if any)
        this.id = object.id ?? AbstractEntity.generateUID();
        this.remoteId = object.remoteId ?? 0;
        this.conversationId = object.conversationId;
        this.conversationRemoteId = object.conversationRemoteId;
        this.content = object.content;
        this.prompt = object.prompt;
        this.role = object.role;
        this.categories = object.categories;
        this.isFinish = object.isFinish ?? false;
        this.createdAt = object.createdAt ?? new Date().toISOString(); // Format as ISO string
    }

    toWordpressJson() {
        let jsonData = {};
        if(this.remoteId !== 0) {
            jsonData['id'] = this.remoteId;
        }
        jsonData['title'] = this.content;
        jsonData['content'] = JSON.stringify(this.courses);
        jsonData['status'] = this.status;
        jsonData['acf'] = {
            conversation_id: this.conversationRemoteId,
            see_more: this.see_more,
            // keywords: this.keywords.join(','),
        };
        return JSON.stringify(jsonData);
    }

    static fromWordpressAPI(jsonArray) {
        jsonArray = jsonArray.map((json) => {
            return {
                role: json.acf['role'],
                conversationId: json.acf['conversation_id'],
                content: json.content['rendered'],
                id: json.acf['id'].trim() === "" ? AbstractEntity.generateUID() : json.acf['id'],
                remoteId: json.id,
                createdAt: json.date
            }
        });
        return MessageEntity.fromList(jsonArray);
    }

    addWord(word) {
        this.content += word;
        return this;
    }

    isSynced = () => this.remoteId !== 0;

    static getCategories(courses) {
        const categoriesSet = new Set(); // Use a Set to keep unique categories

        courses.forEach(course => {
            Object.entries(course.categories).forEach(([url, category]) => {
                // Create a unique identifier for each category
                const uniqueCategory = `${url}|${category}`;
                // Add to the Set (duplicates will be automatically ignored)
                categoriesSet.add(uniqueCategory);
            });
        })

        // Convert the Set back to an array of objects
        const categories = Array.from(categoriesSet).map(categoryString => {
            const [url, category] = categoryString.split('|');
            return { url: url, name: category };
        });

        return categories;
    }

    getJobs() {
        return JobEntity.fromList(this.jobs);
    }

    setError(error) {
        this.content = error;
        return this;
    }

    toWebinifyMessage() {
        return {
            "role": this.role,
            "content": this.content
        }
    }
}