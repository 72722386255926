import React from "react";


export default class JobsWidget extends React.Component {
    onJobClicked = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    render() {
        if (this.props.jobs !== undefined) {
            return <div className="course-categories" data-visible={this.props.visible}>
                <h4>Jobs you might be interested</h4>
                <div className="list">
                    {this.props.jobs !== undefined && this.props.jobs.length > 0 ? this.props.jobs.map((job) => (
                        <a key={job.id} rel="norefferer" onClick={(e) => this.onJobClicked(e, job.url)} className="category" href={job.url} dangerouslySetInnerHTML={{ __html: job.name }}></a>
                    )) : <></>}
                </div>
            </div>;
        }
        return <></>;
    }
}