
import udmey from "../../assets/udmey-icon.png";
import demand from "../../assets/demand.png";
import price from "../../assets/price.png";
import time from "../../assets/time.png";
import arrowRight from "../../assets/arrow-right.png";
import React from "react";

import "./CourseCardWidget.css";
import "./CourseCardWidget.mobile.css";

export default class CourseCardWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            course: props.course
        }

    }

    
    onCourseClick = (url) => {
        window.open(url, '_blank');
    }


    render() {
        return <div key={this.state.course.id} onClick={() => this.onCourseClick(this.state.course.url)} className="card">
            <img src={this.state.course.featured_image} alt="" className="card-image" />
            <span className="card-category">{this.state.course.getCategoryNames()}</span>
            <p className="card-title" dangerouslySetInnerHTML={{ __html: this.state.course.title }} />

            <ul className="card-ul">
                <li className="card-li">
                    {" "}
                    <img src={udmey} alt="" className="card-li-img" />
                    <p className="card-li-text">{this.state.course.provider}</p>
                </li>
                {this.state.course.course_session.trim() ? <li className="card-li">
                    <img src={demand} alt="" className="card-li-img" />
                    <p className="card-li-text">{this.state.course.course_session}</p>
                </li> : <></>}

                <li className="card-li">
                    <img src={price} alt="" className="card-li-img" />
                    <p className="card-li-text">{this.state.course.course_type}</p>
                </li>
                {this.state.course.duration.trim() ? <li className="card-li">
                    {" "}
                    <img src={time} alt="" className="card-li-img" />
                    <p className="card-li-text">{this.state.course.duration}</p>
                </li> : <></>}

            </ul>
            <a href={this.state.course.url}>
                <div className="card-button-div">
                    <p className="card-button-text">Read More</p>
                    <div className="card-button-img-div">
                        <img src={arrowRight} alt="" className="card-button-img" />
                    </div>
                </div>
            </a>
        </div>;
        
    }
}