import AppConst from "../AppConst";
import MessageEntity from "../entity/MessageEntity";
import MessageRepository from "../repository/MessageRepository";
import AuthApi from "./AuthApi";

export default class MessageAPI {

    static async getRemoteMessages() {
        if (AuthApi.isLoggedIn()) {
            const username = localStorage.getItem('app-username');
            const appPassword = localStorage.getItem('app-password');
            const credentials = btoa(`${username}:${appPassword}`);
            const userId = localStorage.getItem('app-user');

            const response = await fetch(`${AppConst.BASE_URL}/wp-json/wp/v2/message?author=${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${credentials}`
                },
            });

            if (response.ok) {
                // here save all the conversations
                const jsonData = await response.json();
                return MessageEntity.fromWordpressAPI(jsonData);
            } else {
                // console.log(response.status);
            }
        }

    }


    /**
     * 
     * @param {MessageEntity} message 
     * @returns {Promise<MessageEntity>}
     */
    static async post(message) {
        if (AuthApi.isLoggedIn()) {
            if (message.isSynced() === false) {
                // there is an ID

                const response = await fetch(`${AppConst.BASE_URL}/wp-json/wp/v2/message`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Basic ${AuthApi.getAuthorizationBearer()}`,
                    },
                    body: message.toWordpressJson()
                });

                if (response.ok) {
                    const jsonResponse = await response.json();
                    message.remoteId = jsonResponse.id;
                    return message;
                } else {
                    // console.log(response.status);
                }

            }
        }

    }

    async addOrUpdateMessage(conversationId, message) {
        const response = await fetch(`${this.baseURL}/wp-json/app/v1/conversation/${conversationId}/message`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(message),
        });

        if (!response.ok) {
            throw new Error(`Error adding/updating message: ${response.statusText}`);
        }

        return response.json();
    }

    async deleteMessage(messageId) {
        const response = await fetch(`${this.baseURL}/wp-json/app/v1/message/${messageId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error deleting message: ${response.statusText}`);
        }

        return response.json();
    }
}