import AbstractEntity from "./AbstractEntity";


export default class JobEntity extends AbstractEntity {
    static TABLE = 'jobs';

    static schema = {
        id: "string",
        remoteId: "number",
        messageId: "string",
        name: "string",
        url: "string",
        createdAt: "string"
    };

    constructor(object) {
        super();  // Call the parent constructor (if any)
        this.id = object.id ?? AbstractEntity.generateUID();
        this.remoteId = object.remoteId ?? 0;
        this.messageId = object.messageId;
        this.name = object.name;
        this.url = object.url;
        this.createdAt = object.createdAt ?? new Date().toISOString(); // Format as ISO string
    }

}