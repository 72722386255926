import AbstractEntity from "./AbstractEntity";

export default class ConversationEntity extends AbstractEntity {
    static TABLE = 'conversations';

    static schema = {
        id: "string",
        remoteId: "number",
        title: "string",
        createdAt: "string"
    };

    constructor(object) {
        super();  // Call the parent constructor (if any)
        this.id = object.id ?? AbstractEntity.generateUID();
        this.remoteId = object.remoteId ?? 0;
        this.title = object.title;
        this.messages = object.messages ?? [];
        this.createdAt = object.createdAt ?? new Date().toISOString(); // Format as ISO string
    }

    toWordpressJson() {
        let jsonData = {};
        jsonData['title'] = this.title;
        jsonData['status'] = this.status;
        return JSON.stringify(jsonData);
    }

    static fromWordpressAPI(jsonArray) {
        jsonArray = jsonArray.map((json) => {
            return {
                title: json.title['rendered'],
                remoteId: json.id,
                id: json.acf['id'],
                createdAt: json.date
            }
        });
        return ConversationEntity.fromList(jsonArray);
    }

    isSynced = () => this.remoteId !== 0;

    getMessages() {
        this.messages.sort((a, b) => a.createdAt > b.createdAt);
        return this.messages;
    }

    /**
     * 
     * @param {MessageEntity} message 
     */
    addMessage(message) {
        this.messages.push(message);
    }

    addWord(word) {
        this.title += word;
        return this;
    }

    setHistoryUrl() {
        const conversationId = this.id;
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('id', conversationId);
        window.history.replaceState({}, '', currentUrl.toString());
    }

}