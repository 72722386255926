import AppConst from "../AppConst";
import ConversationEntity from "../entity/ConversationEntity";
import AuthApi from "./AuthApi";

export default class ConversationAPI {

    static async getAll() {
        if (AuthApi.isLoggedIn()) {
            const username = localStorage.getItem('app-username');
            const appPassword = localStorage.getItem('app-password');
            const credentials = btoa(`${username}:${appPassword}`);
            const userId = localStorage.getItem('app-user');

            const response = await fetch(`${AppConst.BASE_URL}/wp-json/wp/v2/conversation?author=${userId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Basic ${credentials}`
                },
            });

            if (response.ok) {
                const jsonData = await response.json();
                return ConversationEntity.fromWordpressAPI(jsonData);
            } else {
                // console.log(response.status);
                return [];
            }
        }
    }

    async getConversations(userId) {
        const response = await fetch(`${AppConst.BASE_URL}/wp-json/app/v1/conversations/${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching conversations: ${response.statusText}`);
        }

        return response.json();
    }

    async getConversation(conversationId) {
        const response = await fetch(`${AppConst.BASE_URL}/wp-json/app/v1/conversation/${conversationId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching conversation: ${response.statusText}`);
        }

        return response.json();
    }

    /**
     * This function will send to remote database
     * @param {ConversationEntity} conversation 
     * @returns {Promise<ConversationEntity>}
     */
    static async post(conversation) {
        if (AuthApi.isLoggedIn()) {
            const API = conversation.remoteId !== 0
                ? `${AppConst.BASE_URL}/wp-json/wp/v2/conversation/${conversation.remoteId}`
                : `${AppConst.BASE_URL}/wp-json/wp/v2/conversation`;
            
            const response = await fetch(API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Basic ${AuthApi.getAuthorizationBearer()}`,
                },
                body: conversation.toWordpressJson(),
            });

            if (response.ok) {
                const jsonResponse = await response.json();
                conversation.remoteId = jsonResponse.id;
                return conversation;
            } else {
                throw new Error(`Error creating/updating conversation: ${response.statusText}`);
            }
        }
    }


    static async deleteConversation(conversationId) {
        const response = await fetch(`${AppConst.BASE_URL}/wp-json/app/v1/conversation/${conversationId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error deleting conversation: ${response.statusText}`);
        }

        return response.json();
    }
}
