
/**
 * @template T
 * @class AbstractEntity
 */
export default class AbstractEntity {
    // This method will convert the instance properties into a key-value structure
    toJson() {
        const json = {};
        Object.keys(this.constructor.schema).forEach((key) => {
            // Ensure the key is a string (if it's not already)
            json[String(key)] = this[key];
        });
        return json;
    }

    toDB() {
        const json = {};
        Object.keys(this.constructor.schema).forEach((key) => {
            // Ensure the key is a string (if it's not already)
            json[String(key)] = this[key];
        });
        return json;
    }

    static generateUID() {
        return 'xxxx-xxxx-4xxx-yxxx-xxxx-xxxxxxx'.replace(/[xy]/g, function(c) {
            const randomValue = Math.random() * 16 | 0;
            const value = c === 'x' ? randomValue : (randomValue & 0x3 | 0x8);
            return value.toString(16);
        });
    }
    
    
     /**
     * Convert a list of plain objects into instances of the current class.
     * @param {Object[]} objectList - List of plain objects.
     * @returns {T[]} - Array of instances of the current class.
     */
     static fromList(objectList = []) {
        if (!Array.isArray(objectList) || objectList.length === 0) return [];
        return objectList.map((obj) => new this(obj));
    }
}