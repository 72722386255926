import React from "react";
import "./CategoriesWidget.css";
import MessageEntity from "../../entity/MessageEntity";

export default class CategoriesWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }


    render() {
        const {courses} = this.props;
        let categories = MessageEntity.getCategories(courses);

        return <>
            {categories.length > 0 ? <>
                <div className="course-categories" data-visible={this.props.visible}>
                    <h4>Related categories</h4>
                    <div className="list">
                        {categories.length > 0 ? categories.map((course, index) => (
                            <a key={index} rel="norefferer" onClick={(e) => this.props.onCategoryClicked(e, course)} className="category" href={course.url} dangerouslySetInnerHTML={{ __html: course.name }}></a>
                        )) : <></>}
                    </div>
                </div>
            </> : <></>}</>
    }
}