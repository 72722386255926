import AbstractEntity from "./AbstractEntity";

export default class CourseEntity extends AbstractEntity {
    static TABLE = 'courses';

    static schema = {
        id: "number", // also the remoteId
        messageId: "string", // local messageId
        url: "string",
        title: "string",
        featured_image: "string",
        duration: "string",
        course_session: "string",
        level: "string",
        language: "string",
        taught_by: "string",
        categories: "string",
        provider: "string",
        course_type: "string",
        createdAt: "string",
        isFinish: "bool"
    };

    constructor(object) {
        super(object);
        this.id = object.id;
        this.title = object.title;
        this.url = object.url;
        this.featured_image = object.featured_image;
        this.course_type = object.course_type;
        this.duration = object.duration;
        this.course_session = object.course_session;
        this.level = object.level;
        this.language = object.language;
        this.taught_by = object.taught_by;
        this.categories = object.categories;
        this.provider = object.provider;
        this.createdAt = object.createdAt ?? new Date().toISOString(); // Format as ISO string
    }

    getCategoryNames() {
       //return Object.values(this.categories).join(', ');
       const categoryNames = Object.values(this.categories);

       // Get the last entry
       const lastEntry = categoryNames[categoryNames.length - 1];
       return lastEntry;
    }

}