import AppConst from "../AppConst";

export default class ChatApi {

    /**
     * 
     * @param {str} text 
     * @param {Conversation} currentConversation 
     * @returns 
     */
    static async search(text, currentConversation = null) {
        
        let url = new URL(AppConst.BASE_URL + '/wp-json/custom/v1/handle-request');
        url.searchParams.append('q', text);

        let previousMessages = null;
        if (currentConversation != null) {
            let messages = currentConversation.getMessages();
            let previousCourses = [];

            // Loop through each message except the last one
            messages.forEach((message) => {
                // Check if the message contains courses
                if (message.courses) {
                    message.courses.forEach((prevCourse) => {
                        // Add course to the previousCourses array if not already included
                        if (!previousCourses.some((c) => c.id === prevCourse.id)) {
                            previousCourses.push(prevCourse);
                        }
                    });
                }
            });

            previousMessages = messages;

            let excludeIds = previousCourses.map(course => course.id);
            if (excludeIds.length > 0) {
                url.searchParams.append('exclude_ids', excludeIds.join(','));
            }
        }

        try {
            let response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(previousMessages)
            });

            if (response.status !== 200) {
                throw response.status;
            }

            return response;
        } catch (e) {
            // console.log(e);
            // handle error
        }

    }
}