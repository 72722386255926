import 'prismjs';
import 'prismjs/themes/prism.css';  // You can choose other themes like 'prism-tomorrow.css'
import 'prismjs/components/prism-javascript.min.js'; // Include any language you need (e.g., javascript, python)
import React from "react";
import { MessageSender } from "../../model/Message";
import "./MessageWidget.css";
import WebinifySpark from "../../api/WebinifySpark";
import AppIcon from "../../assets/favicon.png";
import ReactMarkdown from 'react-markdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faArrowUpRightFromSquare, faCopy } from '@fortawesome/free-solid-svg-icons';
import ConversationRepository from '../../repository/ConversationRepository';
import MessageRepository from '../../repository/MessageRepository';
import AppConfig from '../../AppConfig';
import MessageEntity from '../../entity/MessageEntity';

import CategoriesWidget from './CategoriesWidget';
import JobsWidget from './JobsWidget';
import CoursesWidget from './CoursesWidget';
import ChatApi from '../../api/Api';
import JobRepository from '../../repository/JobRepository';
import CourseRepository from '../../repository/CourseRepository';
import CourseEntity from '../../entity/CourseEntity';
import JobEntity from '../../entity/JobEntity';
import MessageAPI from '../../api/MessageApi';
import ConversationAPI from '../../api/ConversationApi';


export default class MessageWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: props.message,
            conversation: props.conversation,
            limit: 4,
            jobsVisible: false,
            jobs: [],
            courses: null,
            isCategoriesVisible: false,
        }

        this.webinifySpark = new WebinifySpark();
        this.indexDb = indexedDB.open(AppConfig.DATABASE_NAME, AppConfig.VERSION);
        this.db = null;

        this.indexDb.addEventListener('success', (ev) => {
            this.db = ev.target.result;

            this.conversationRepository = new ConversationRepository(this.db);
            this.messageRepository = new MessageRepository(this.db);
            this.jobRepository = new JobRepository(this.db);
            this.courseRepository = new CourseRepository(this.db);

            this.messageRepository.getBy({ conversationId: this.state.conversation.id }).then((res) => {
                if (res.length > 0) {
                    let messages = MessageEntity.fromList(res)
                        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                    const lastMessage = messages[messages.length - 1]; // which is also the prompt message
                    if (lastMessage.id === this.state.message.id && this.state.message.role === MessageSender.USER) {
                        const futureMessage = new MessageEntity({
                            prompt: lastMessage.content,
                            role: MessageSender.ASSISTANT,
                            content: "",
                            conversationId: this.state.conversation.id
                        });

                        this.props.onAssistantMessageAdded(futureMessage);
                    }

                }
            });

            this.jobRepository.getBy({ messageId: this.state.message.id }).then((res) => {
                let jobs = JobEntity.fromList(res);

                this.setState({
                    jobs: jobs
                });
            });

            this.courseRepository.getBy({ messageId: this.state.message.id }).then((res) => {
                let courses = CourseEntity.fromList(res);

                this.setState({
                    courses: courses
                });
            });

        });
    }

    componentDidMountAsync = async () => {
        if (this.state.message.role === MessageSender.USER) {
            if (this.state.message.remoteId === 0) {
                let message = await MessageAPI.post(this.state.message);
                await this.messageRepository.update(message);
            }
            if(this.state.conversation.remoteId === 0) {
                let message = this.state.message;
                let conversation = await ConversationAPI.post(this.state.conversation);
                message.conversationRemoteId = conversation.remoteId;
                await this.messageRepository.update(message);
                await this.conversationRepository.update(conversation);
                this.setState({
                    conversation: conversation
                });
            }
        } else if (this.state.message.role === MessageSender.ASSISTANT && this.state.message.content.length < 5) {
            // this is for when the current message is expected to be the assistant message.

            // this is for the live stream AI
            this.webinifySpark.chat(
                this.state.conversation.messages.map((m) => m.toWebinifyMessage()),
                (word) => {
                    this.setState((prevState) => ({
                        message: prevState.message.addWord(word),
                    }));
                    this.props.scrollToBottom();
                },
                () => {
                    let message = this.state.message;
                    message.isFinish = true;
                    message.conversationRemoteId = this.state.conversation.remoteId;
                    this.props.onFinish(this.state.message);
                    this.setState({
                        message: message
                    });

                    setTimeout(() => {
                        this.jobRepository.getBy({ messageId: this.state.message.id }).then((res) => {
                            let jobs = JobEntity.fromList(res);

                            this.setState({
                                jobs: jobs
                            });
                        });

                        this.courseRepository.getBy({ messageId: this.state.message.id }).then((res) => {
                            let courses = CourseEntity.fromList(res);

                            this.setState({
                                courses: courses
                            });
                        });
                    }, 300);
                },
                () => {
                    let message = this.state.message;
                    message.isFinish = true;
                    this.setState({
                        message: message
                    });

                    this.setState((prevState) => ({
                        message: prevState.message.setError("An error occurred"),
                    }));

                }
            );

            // this will run the request in parallel
            ChatApi.search(this.state.message.prompt).then(async (response) => {
                const jsonData = await response.json();
                let jobs = JobEntity.fromList(jsonData.jobs);
                let courses = CourseEntity.fromList(jsonData.results);
                for (let index in jobs) {
                    jobs[index].messageId = this.state.message.id;
                }
                for (let index in courses) {
                    courses[index].messageId = this.state.message.id;
                }
                this.courseRepository.updateAll(courses);
                this.jobRepository.updateAll(jobs);
                this.setState({
                    jobs: jobs,
                    courses: courses,
                });
            });


        }

        // const response = await ChatApi.search(prompt, conversation);
        // const jsonData = await response.json();

        // futureMessage.content = jsonData.message;
        // futureMessage.see_more = jsonData.see_more;
        // futureMessage.keywords = jsonData.keywords;
        // futureMessage.jobs = Job.fromList(jsonData.jobs);
        // futureMessage.courses = Course.fromList(jsonData.results).slice(0, 4);
        // conversation.title = jsonData.title;
        // futureMessage.isLoading = false;
        // futureMessage.status = AppConst.POST_PUBLISH;

        // conversation.addMessage(futureMessage);
        // await conversation.saveToRemote();
        // conversation = ConversationRepository.save(conversation);
        // this.setConversation(conversation);
        // this.setConversations(ConversationRepository.getConversations());

        // this.setIsInputDisabled(false);
        // this.scrollToBottom();
    }

    componentDidMount() {
        this.componentDidMountAsync();
    }

    userMessage() {
        return <div key={this.state.message.id} className="your-prompt">
            <div>
                <p>{this.state.message.content}</p>
            </div>
        </div>;
    }

    handleCopy = () => {
        let message = this.state.message;
        navigator.clipboard.writeText(message.content)
            .then(() => {
                // console.log('Content copied to clipboard');
            })
            .catch((err) => {
                // console.error('Error copying text: ', err);
            });
    }


    handleRetry = async () => {
        let message = this.state.message;
        message.content = "";
        message.isFinish = false;
        this.setState({
            message: message,
            limit: 4,
        });
        let messages = this.state.conversation.messages.filter((m) => m.id !== message.id);

        // Get the index of the current message
        let clientMessages = this.state.conversation.messages.filter((m) => m.role !== MessageSender.SYSTEM)
            .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
        let nextMessages = clientMessages.slice(clientMessages.indexOf(message) + 1);
        for (let index in nextMessages) {
            const messageToDelete = nextMessages[index];
            await this.messageRepository.delete(messageToDelete.id);
        }
        let conversation = this.state.conversation;
        conversation.messages = await this.messageRepository.getBy({ conversationId: conversation.id });
        this.props.setConversation(conversation);

        this.webinifySpark.chat(
            messages.map((m) => m.toWebinifyMessage()),
            (word) => {
                this.setState((prevState) => ({
                    message: prevState.message.addWord(word),
                }));
            },
            () => {
                message.isFinish = true;
                this.setState({
                    message: message
                });

                this.props.onFinish(this.state.message);

                setTimeout(() => {
                    this.jobRepository.getBy({ messageId: this.state.message.id }).then((res) => {
                        let jobs = JobEntity.fromList(res);

                        this.setState({
                            jobs: jobs
                        });
                    });

                    this.courseRepository.getBy({ messageId: this.state.message.id }).then((res) => {
                        let courses = CourseEntity.fromList(res);

                        this.setState({
                            courses: courses
                        });
                    });
                }, 300);
            },
            () => {
                message.isFinish = true;
                this.setState({
                    message: message
                });
                this.setState((prevState) => ({
                    message: prevState.message.setError("An error occurred"),
                }));
            }
        );

        // this will run the request in parallel
        ChatApi.search(this.state.message.prompt).then(async (response) => {
            const jsonData = await response.json();
            let jobs = JobEntity.fromList(jsonData.jobs);
            let courses = CourseEntity.fromList(jsonData.results);
            for (let index in jobs) {
                jobs[index].messageId = this.state.message.id;
            }
            for (let index in courses) {
                courses[index].messageId = this.state.message.id;
            }
            this.courseRepository.updateAll(courses);
            this.jobRepository.updateAll(jobs);
            this.setState({
                jobs: jobs,
                courses: courses,
            });
        });
    }

    relatedCategoriesButtonClicked = (bool = undefined) => {
        this.setState({ isCategoriesVisible: bool ?? !this.state.isCategoriesVisible });
        this.props.scrollToBottom(150);
    }


    jobsButtonClicked = (bool = undefined) => {
        this.setState({ jobsVisible: bool ?? !this.state.jobsVisible });
        this.props.scrollToBottom(150);
    }

    onCategoryClicked = (e, category) => {
        e.preventDefault();
        const message = new MessageEntity({
            role: MessageSender.USER,
            content: "I'm looking to learn " + category.name,
            conversationId: this.state.conversation.id
        });
        this.props.onAssistantMessageAdded(message);
    }

    notHappy = () => {
        let limit = this.state.limit;
        limit += 4;
        this.setState({
            limit: limit
        });
    }

    assistantMessage() {
        return <div key={this.state.message.id} className="search-prompt" >
            <div className="content">
                <div className="avatar">
                    <img src={AppIcon}></img>
                </div>
                <div className='message-content'>
                    <div className="search-prompt-heading">
                        <ReactMarkdown>{this.state.message.content ?? "I'm working on your request. Please be with me..."}</ReactMarkdown>
                    </div>


                    {this.state.message.isFinish ? <div className='loadable' data-skeleton={this.state.courses === null || this.state.courses.length === 0}>
                        <CoursesWidget limit={this.state.limit} courses={this.state.courses ?? []}></CoursesWidget>
                        {this.state.courses === null || this.state.courses.length === 0 ? <></> : <div className="quick-actions">
                            {this.state.courses !== null ? <button data-active={this.state.isCategoriesVisible} className="notHappy dark-blue" onClick={() => {
                                if (this.state.isCategoriesVisible) {
                                    this.relatedCategoriesButtonClicked(false);
                                } else {
                                    this.relatedCategoriesButtonClicked(true);
                                }
                                this.jobsButtonClicked(false);

                            }}>Related Categories</button> : <></>}

                            {this.state.jobs ? <button data-active={this.state.jobsVisible} className="notHappy blue" onClick={() => {
                                if (this.state.jobsVisible) {
                                    this.jobsButtonClicked(false);
                                } else {
                                    this.jobsButtonClicked(true);
                                }

                                this.relatedCategoriesButtonClicked(false);
                            }}>Related Jobs</button> : <></>}

                            {this.state.courses ? <button className="notHappy light-blue" onClick={this.notHappy}>Suggest me more courses</button> : <></>}

                            {true != true ? <a className="seeMore" target="_blank" rel="noreferrer" href={this.state.message.see_more}>
                                <span>See more</span>
                                <FontAwesomeIcon color="#ffffff" icon={faArrowUpRightFromSquare} />
                            </a> : <></>}
                        </div>}


                    </div> : <></>}


                    <CategoriesWidget visible={this.state.isCategoriesVisible} scrollToBottom={this.props.scrollToBottom} onCategoryClicked={this.onCategoryClicked} courses={this.state.courses ?? []} conversation={this.state.conversation} />
                    <JobsWidget visible={this.state.jobsVisible} jobs={this.state.jobs} />

                    {this.state.message.isFinish ? <div className='buttons'>
                        <button onClick={this.handleCopy}>
                            <FontAwesomeIcon icon={faCopy} />
                        </button>
                        <button onClick={this.handleRetry}>
                            <FontAwesomeIcon icon={faRedo} />
                        </button>
                    </div> : <></>}
                </div>
            </div>


        </div>;
    }

    findJobs() {
        // here call the api to get jobs
    }

    findCourses() {
        // here call the api to get courses
    }

    render() {
        return this.state.message.role === MessageSender.USER ? this.userMessage() : this.assistantMessage();
    }
}