import React from "react";
import CourseCardWidget from "./CourseCardWidget";
import "./CoursesWidget.css";
import "./CoursesWidget.mobile.css";

export default class CoursesWidget extends React.Component {
    render() {
        return <div className="course-listing">
            {(this.props.courses.slice(0, this.props.limit)).map((c) => <CourseCardWidget key={c.id} course={c} ></CourseCardWidget>)}
        </div>
    }
}